import { getFileNameForComponent } from 'o365.modules.CodeBuilder.constants.ts';
import { TreeObject, TreeNode } from 'o365.modules.TreeObject.ts';
import getTreeObject from 'o365.modules.TreeObject.ts';
import { getOrCreateDataObject } from 'o365.vue.ts';
import NodeItem from 'o365.modules.CodeBuilder.NodeItem.ts';
import CodeGenerator from 'o365.modules.CodeBuilder.CodeGenerator.ts';

const dsFields = getOrCreateDataObject({
    id: "o_dsFields_CodeBuilder",
    fields: [{ name: "name" }]
})

dsFields.enableClientSideHandler([]);

export default class CodeBuilder {

    children: Array<CodeBuilder>;
    treeObject: TreeObject;
    dataObjects: Array<any>;
    html: string = "";
    code: string = "";
    markdown: string = "";
    markdownCode: string = "";


    get dsFields() {
        return dsFields;
    }



    constructor(id: string, pDataObjects: Array<any>) {
        this.treeObject = getTreeObject({ id: id })
        this.dataObjects = pDataObjects;
    }



    async addNode(pNode: any) {
        const vNode = new NodeItem(pNode, this.dataObjects, this);
        if (this.treeObject.currentNode) {
            vNode.count = 0
            this.treeObject.currentNode.addChildren([vNode], false);
            if (this.treeObject.currentNode.item) {
                //  vNode.count = this.treeObject.currentNode.parent.children.length-1;
                this.treeObject.currentNode.item.count = this.treeObject.currentNode.children.length;
            }
            vNode.parentFileName = this.treeObject.currentNode.item.fileName;
            //check for default triggers, if Form add corresponding inpts
            // vNode.count = this.treeObject.currentNode.children.length-1;

        } else {
            this.treeObject.node.addChildren([vNode], false);
            //  vNode.count = this.treeObject.node.children.length-1;
            //this.treeObject.currentNode = this.treeObject.node.children[0];
        }

        await vNode.updateDeclarations();
        this.updateHtml();
        return;
    }

    async addComponent(pComponent: string) {
        const vFileName = getFileNameForComponent(pComponent);
        if (!vFileName) {
            console.warn(pComponent, "Do not exists");
            return;
        }

        return this.addNode({
            fileName: vFileName,
            count: 0,
            id: vFileName,
        })

    }

    removeNode(pNode: any) {
        const vIndex = this.treeObject.currentNode.children.findIndex(x => x.item.id === pNode.id);
        this.treeObject.currentNode.children.splice(vIndex, 1);
        this.treeObject.currentNode.item.count = this.treeObject.currentNode.children.length;
        this.updateHtml();
    }

    addNodesFromString(pString) {

    }


    nodeClick(pNode: any) {
        // console.log(pNode);
    }

    changeHtml(pName: string, pType: string) {
        this.updateHtml();
    }

    updateHtml() {
        this.html = this._generateHtml(this.treeObject.node);
        this.markdown = "```html\n" + this.html + "\n ```";
        this.updateCode();
        this.markdownCode = "```js \n" + this.code + "\n ```";
    }

    updateCode() {
        // this.code = this._generateCode(this.treeObject.node);
        this.code = CodeGenerator.generateCode(this.treeObject.node);

    }

    private _generateHtml(pNode: TreeNode) {
        const vNode = pNode.item;
        let vHtml = "";
        const vSlots = vNode ? vNode.slots.filter(x => x.selected) : [];
        if (vNode) {
            vHtml += `<${vNode.componentName}`
            vHtml += vNode.props.getString();
            if (vSlots.length > 0 || pNode.children.length > 0) {
                vHtml += `>`
            } else {
                vHtml += `/>`
            }
        }

        if (pNode.children.length > 0) {

            pNode.children.forEach(child => {
                vHtml += `\r\n  `;
                vHtml += this._generateHtml(child)
            })
            vHtml += `\r\n  `;

        }

        if (vNode) {
            vSlots.forEach((slot: any) => {
                vHtml += `\r\n  `;
                vHtml += slot.example;
            })
        }

        if (vNode && (vSlots.length > 0 || pNode.children.length > 0))
            vHtml += `\r\n</${vNode.componentName}>`


        return vHtml;
    }



}





